import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const Transparency = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Set up the scene
    const scene = new THREE.Scene();

    // Adjust the camera to focus closely on the cube
    const camera = new THREE.PerspectiveCamera(50, 2, 0.1, 1000);
    camera.position.set(0, 0, 14); // Move closer to focus only on the cube

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Create a group to hold the cube layers
    const cubeGroup = new THREE.Group();

    // Number of layers in the cube
    const numberOfLayers = 10;
    const layerDepth = 0.5; // Depth of each layer

    // Create multiple transparent layers to form the cube
    for (let i = 0; i < numberOfLayers; i++) {
      const layerGeometry = new THREE.BoxGeometry(5, 5, layerDepth);
      const layerMaterial = new THREE.MeshStandardMaterial({
        color: 0x4299cf, // Light blue color
        transparent: true,
        emissive: 0x4299cf,
        opacity: 0.2 + 0.05 * i, // Gradually increase opacity for each layer
      });

      const layerMesh = new THREE.Mesh(layerGeometry, layerMaterial);
      layerMesh.position.set(0, 0, -numberOfLayers / 2 * layerDepth + i * layerDepth);
      cubeGroup.add(layerMesh);
    }

    // Add the cube group to the scene
    scene.add(cubeGroup);

    // Add lighting for better visual appearance
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const pointLight1 = new THREE.PointLight(0x90cdf4, 1.5, 50);
    pointLight1.position.set(10, 15, 10);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0xffa8a8, 1.2, 50);
    pointLight2.position.set(-15, -10, 5);
    scene.add(pointLight2);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      cubeGroup.rotation.y += 0.005; // Rotate the cube group for a dynamic effect
      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} style={{ width: '300px', height: '150px' }} />;
};

export default Transparency;
