import React from 'react';
import ProfileSection from './ProfileSection';
import TaskSection from './TaskSection';
import DurationSection from './DurationSection';
import AppointmentsSection from './AppointmentsSection';
import WhatsNewSection from './WhatsNew';
import Navbar from './Navbar';
import Footer from './Footer';
import UserBlogsSection from './UserBlogsSection';

const Dashboard = () => {
  return (
    <div className="min-h-screen flex flex-col bg-black">
      {/* Navbar */}
      <Navbar />

      {/* Main Dashboard Content */}
      <div className="flex-grow w-full mx-auto mt-16"> {/* Add margin-top (mt-8) */}
          {/* Profile Section (Full Width) */}
          <div className="lg:col-span-3">
            <ProfileSection />
          </div>

          {/* Left Side */}
          <div className='bg-gradient-to-b from-black to-white grid grid-cols-1 lg:grid-cols-3 gap-4 p-5 pt-10'>
            <div className="space-y-4">
              <DurationSection />
              <AppointmentsSection />
              {/* <WhatsNewSection /> */}
            </div>

            {/* Right Side */}
            <div className="lg:col-span-2 space-y-4">
              <TaskSection />
            </div>
          </div>
          <div className="w-full p-5 bg-white">
            <UserBlogsSection /> {/* Full width blogs section */}
          </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Dashboard;
