import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import Logo from '../../assets/Logo1.jpg';

const AboutAnimation = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Set up the scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff); // Set background to white

    // Use container dimensions for the camera aspect ratio
    const container = mountRef.current;
    const width = container.clientWidth;
    const height = container.clientHeight;

    // Set camera properties
    const camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000);
    camera.position.z = 30;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(width, height);
    container.appendChild(renderer.domElement);

    // Load the logo texture using the imported Logo image
    const textureLoader = new THREE.TextureLoader();
    const logoTexture = textureLoader.load(Logo);

    // Create a logo mesh and add it directly to the scene (fixed position)
    const logoGeometry = new THREE.PlaneGeometry(10, 10);
    const logoMaterial = new THREE.MeshBasicMaterial({ map: logoTexture, transparent: true });
    const logoMesh = new THREE.Mesh(logoGeometry, logoMaterial);
    logoMesh.position.set(0, 0, 0); // Fixed position in the center
    scene.add(logoMesh);

    // Create a group to hold the crypto token structure (excluding the fixed logo)
    const tokenGroup = new THREE.Group();

    // Create a ring to surround the token, symbolizing its digital aspect (emissive ring)
    const ringGeometry1 = new THREE.TorusGeometry(6.5, 0.1, 32, 200);
    const ringMaterial1 = new THREE.MeshStandardMaterial({
      color: 0x4299cf, // Lighter blue color
      emissive: 0x4299cf,
    });
    const ring1 = new THREE.Mesh(ringGeometry1, ringMaterial1);
    ring1.rotation.x = Math.PI / 2; // Rotate the ring to align with the plane
    tokenGroup.add(ring1);

    // Create a second ring, tilted for a dynamic appearance (another emissive ring)
    const ringGeometry2 = new THREE.TorusGeometry(7, 0.1, 32, 200);
    const ringMaterial2 = new THREE.MeshStandardMaterial({
      color: 0xab0a0f, // Lighter red color
      emissive: 0xab0a0f,
    });
    const ring2 = new THREE.Mesh(ringGeometry2, ringMaterial2);
    ring2.rotation.y = Math.PI / 4; // Tilt the ring for visual effect
    tokenGroup.add(ring2);

    // Add the token group to the scene
    scene.add(tokenGroup);

    // Add lighting for better visual appearance
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const pointLight1 = new THREE.PointLight(0x90cdf4, 1.5, 50);
    pointLight1.position.set(10, 15, 10);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0xffa8a8, 1.2, 50);
    pointLight2.position.set(-15, -10, 5);
    scene.add(pointLight2);

    // Function to update the scale and position based on window size
    const updateScaleAndPosition = () => {
      if (window.innerWidth < 768) {
        // Mobile screens
        tokenGroup.scale.set(1.3, 1.3, 1.3); // Larger size for desktop
        logoMesh.scale.set(1.3, 1.3, 1.3); // Larger logo size for desktop
        tokenGroup.position.set(0, 0, 0); // Ensure the group is centered
        logoMesh.position.set(0, 0, 0); // Ensure the logo is centered
      } else if (window.innerWidth < 1200) {
        // Large screens
        tokenGroup.scale.set(1.5, 1.5, 1.5); // Larger size for desktop
        logoMesh.scale.set(1.5, 1.5, 1.5); // Larger logo size for desktop
        tokenGroup.position.set(0, 0, 0); // Ensure the group is centered
        logoMesh.position.set(0, 0, 0); // Ensure the logo is centered
      } else {
        // Large screens
        tokenGroup.scale.set(1.5, 1.5, 1.5); // Larger size for desktop
        logoMesh.scale.set(1.5, 1.5, 1.5); // Larger logo size for desktop
        tokenGroup.position.set(0, 0, 0); // Ensure the group is centered
        logoMesh.position.set(0, 0, 0); // Ensure the logo is centered
      }
    };

    // Initial setup for scale and positioning
    updateScaleAndPosition();

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      tokenGroup.rotation.y += 0.005; // Rotate the entire token structure for a dynamic effect
      tokenGroup.rotation.x += 0.002; // Add slight rotation for more visual interest
      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      const newWidth = container.clientWidth;
      const newHeight = container.clientHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
      updateScaleAndPosition(); // Update scale and position on window resize
    };

    window.addEventListener('resize', handleResize);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      container.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        width: '100%',
        height: '100%',
        minHeight: '400px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    />
  );
};

export default AboutAnimation;
