import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

function InstitutionDashboard() {
//   const { user, logout } = useContext(AuthContext);

//   const handleLogout = async () => {
//     try {
//       await logout();
//       // Redirect to home page or login page
//     } catch (error) {
//       console.error('Failed to log out', error);
//     }
//   };

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <h1 className="text-xl font-bold">Institution Dashboard</h1>
              </div>
            </div>
            <div className="flex items-center">
              <button
                // onClick={handleLogout}
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <h2 className="text-2xl font-semibold text-gray-900">Welcome, Institution User</h2>
          {/* Add your institution dashboard content here */}
          Institution Dashboard
        </div>
      </div>
    </div>
  );
}

export default InstitutionDashboard;