import React from 'react';

const DurationSection = () => {
  const currentStreak = 5;
  const totalDays = 8;
  const progressPercentage = (currentStreak / totalDays) * 100;
  const timer = "Next Donation in 16:54:13";

  return (
    <div className="p-4 rounded-lg shadow-md w-full shadow-2xl mb-10">
      {/* Streak number with fire icon */}
      <div className="flex justify-between">
        <div className="flex flex-col items-center">
          {/* Aligning streak number and fire icon in a row */}
          <div className="flex items-center">
            <h2 className="text-2xl font-bold text-blue-500">{currentStreak}</h2>
            <span className="text-xl ml-2 animate-blaze">🔥</span>
          </div>
          <p className="text-sm text-gray-500">Current Streak</p>
        </div>

        {/* Add to Calendar */}
        <p className="text-sm text-gray-400 self-center cursor-pointer">Add to Calendar</p>
      </div>

      {/* Weekday progress (Progress Bar) */}
      <div className="mt-10">
        <p className="text-sm text-gray-500 mb-2">Progress</p>
        <div className="w-full bg-gray-200 rounded-full h-4">
          <div
            className="bg-blue-500 h-4 rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <div className="flex justify-between mt-2 text-sm text-gray-500">
          <span>Start</span>
          <span>{totalDays} Days</span>
        </div>
      </div>

      {/* Timer */}
      <div className="mt-4 text-center">
        <span className="bg-golden text-black py-1 px-3 rounded-full text-sm">
          {timer}
        </span>
      </div>

      {/* Inline CSS for the blazing fire animation */}
      <style jsx>{`
        @keyframes blaze {
          0%, 100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.2);
          }
        }
        .animate-blaze {
          animation: blaze 1s infinite;
        }
      `}</style>
    </div>
  );
};

export default DurationSection;
