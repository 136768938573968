import React from 'react';
import network from '../../assets/Network1.png'; // Update this path to the correct image
import Transparency from './Transparency';
import DonorRewardSign from './DonerReward';
import CommunityEmpowermentSign from './CommunityEmpowerment';

const WhyTrustBlu = () => {
  return (
    <section id="whytrustblu" className="hero-section bg-white flex items-center justify-center pb-20 px-4 mt-20 md:mt-40">
      <div className="container mx-auto flex flex-col items-start gap-10 px-4">

        <div className="flex flex-col items-start gap-6 w-full">
          {/* Icon and Title */}
          <div className="flex items-center gap-4">
            <div className="icon-container">
              <img
                src={network}
                alt="Icon"
                className="w-12 h-12"
              />
            </div>
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold leading-tight text-customBlue">
              Why TrustBlu
            </h2>
          </div>

          {/* Description */}
          <div className="text-container w-full">
            <p className="text-base sm:text-lg text-gray-600 text-justify mb-5">
              TrustBlu is committed to enhancing the blood donation process by leveraging cutting-edge Blockchain technology for transparency, rewards, and community empowerment.
            </p>
          </div>
        </div>

        {/* Feature Cards */}
        <div className="flex flex-col md:flex-row items-stretch justify-center gap-10 mx-0 md:mx-20 mb-20">
          {/* Card 1 */}
          

          <div className="flex flex-col items-center text-left w-full md:w-1/3 p-4 shadow-lg rounded-lg border border-gray-200">
            <div className="icon-container flex flex-col items-center mb-4">
              <DonorRewardSign />
              <h3 className="text-lg sm:text-xl font-semibold mb-2 text-center">Donor Rewards</h3>
              <p className="text-gray-600 text-center">
                Earn unique rewards for your contributions through our NFT system.
              </p>
            </div>
          </div>

          {/* Card 2 */}
          <div className="flex flex-col items-center text-left w-full md:w-1/3 p-4 shadow-lg rounded-lg border border-gray-200">
            <div className="icon-container flex flex-col items-center mb-4">
              <CommunityEmpowermentSign />
              <h3 className="text-lg sm:text-xl font-semibold mb-2 text-center">Community Empowerment</h3>
              <p className="text-gray-600 text-center">
                We aim to build a connected community that supports each other through efficient blood donation workflows.
              </p>
            </div>
          </div>

          {/* Card 3 */}
          

          <div className="flex flex-col items-center text-left w-full md:w-1/3 p-4 shadow-lg rounded-lg border border-gray-200">
            <div className="icon-container flex flex-col items-center mb-4">
              <Transparency />
              <h3 className="text-lg sm:text-xl font-semibold mb-2 text-center">DoNet</h3>
              <p className="text-gray-600 text-center">
              <strong>[PHASE II]</strong> The Donation Network, aims to establish a robust and interconnected community that brings donors, recipients, and healthcare providers together. 
              </p>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default WhyTrustBlu;
