import React from 'react';
import Tilt from 'react-parallax-tilt';
import network from '../../assets/Network1.png'; // Update this path to the correct image
import AboutAnimation from './AboutAnimation';

const About = () => {
  return (
    <section className="hero-section bg-white flex items-center justify-center  px-4 -mt-20 md:mt-0">
      <div className="container mx-auto flex flex-col md:flex-row items-start gap-10 px-4">

      <div className="flex flex-col items-start gap-6 w-full">
  {/* Icon and Title */}
  <div className="flex items-center gap-4">
    <div className="icon-container">
      <img
        src={network}
        alt="Icon"
        className="w-12 h-12"
      />
    </div>
    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold leading-tight text-customBlue">
      About
    </h2>
  </div>

  {/* Description */}
  <div className="text-container w-full">
    <p className="text-base sm:text-lg text-gray-600 text-justify mb-5">
      TrustBlu is a blockchain-based platform dedicated to transforming the blood donation ecosystem.
    </p>
    <p className="text-base sm:text-lg text-gray-600 text-justify mb-5">
      Our mission is to accelerate blood donations by rewarding donors with meaningful incentives, fostering a community of like-minded individuals, and making the donation process seamless and enjoyable.
    </p>
    <p className="text-base sm:text-lg text-gray-600 text-justify mb-5">
      TrustBlu also aspires to create a donor-to-patient blood donation management system for healthcare institutions, providing near real-time tracking, easy traceability, audibility, and increased accountability.
    </p>
    <p className="text-base sm:text-lg text-gray-600 text-justify mb-5">
      As a comprehensive solution, TrustBlu aims to revolutionize the entire blood donation ecosystem—empowering donors, patients, and healthcare providers with transparency and efficiency every step of the way.
    </p>
  </div>
</div>




        {/* 3D Tilt Effect */}
<Tilt
  className="tilt-container w-full md:w-1/2 flex items-center justify-center mt-8 md:mt-0"
  tiltMaxAngleX={25}
  tiltMaxAngleY={25}
  scale={1.1}
  transitionSpeed={400}
>
  <div className="w-full max-w-xs md:max-w-md lg:max-w-lg h-auto">
    <AboutAnimation />
  </div>
</Tilt>


      </div>
    </section>
  );
};

export default About;
