import React from 'react';
import network from '../../assets/Network1.png';
import nftImage1 from '../../assets/NFT1.webp';
import nftImage2 from '../../assets/NFT2.webp';
import nftImage3 from '../../assets/NFT3.webp';
import nftImage4 from '../../assets/NFT4.webp';
import nftImage5 from '../../assets/NFT5.webp';
import nftImage6 from '../../assets/NFT9.webp';
import nftImage7 from '../../assets/NFT7.webp';
import nftImage8 from '../../assets/NFT9.webp';
import nftImage9 from '../../assets/NFT8.webp';
import nftImage10 from '../../assets/nft2.png';
import nftImage11 from '../../assets/NFT1.webp';
import nftImage12 from '../../assets/NFT5.webp';
import '../Landing/Reward.css';
import { motion } from 'framer-motion';

const nftData = [
  { id: 1, image: nftImage1, title: 'NFT 1' },
  { id: 2, image: nftImage2, title: 'NFT 2' },
  { id: 3, image: nftImage3, title: 'NFT 3' },
  { id: 4, image: nftImage4, title: 'NFT 4' },
  { id: 5, image: nftImage5, title: 'NFT 5' },
  { id: 6, image: nftImage6, title: 'NFT 6' },
  { id: 7, image: nftImage7, title: 'NFT 7' },
  { id: 8, image: nftImage8, title: 'NFT 8' },
  { id: 9, image: nftImage9, title: 'NFT 9' },
  { id: 10, image: nftImage10, title: 'NFT 10' },
  { id: 11, image: nftImage11, title: 'NFT 11' },
  { id: 12, image: nftImage12, title: 'NFT 12' },
];

const Reward = () => {
  return (
    <section className="hero-section bg-white flex flex-col items-center justify-center pt-20">
      <div className="container mx-auto flex flex-col md:flex-row items-start px-4">

        <div className="flex flex-col items-start gap-6 w-full">
          {/* Icon and Title */}
          <div className="flex items-center gap-4">
            <div className="icon-container">
              <img
                src={network}
                alt="Icon"
                className="w-12 h-12"
              />
            </div>
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold leading-tight text-customBlue">
              Rewards
            </h2>
          </div>

          {/* Description */}
          <div className="text-container w-full">
            <p className="text-base sm:text-lg text-gray-600 text-justify mb-5 mx-5">
              At TrustBlu, we believe every donation deserves recognition. Earn exciting rewards for your contributions, celebrate your impact, and be part of a community that values each effort in helping others.
            </p>
          </div>
        </div>
      </div>

     {/* NFT Section */}
     <div className="mt-10 py-10 w-full bg-gradient-to-l from-blue-500 to-red-500 flex flex-col justify-center">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-5 w-full px-4 relative">
          {nftData.map((nft, index) => (
            <div
              key={nft.id}
              className={`relative ${
                index === 4
                  ? 'lg:-translate-x-16 lg:translate-y-20 z-20'
                  : index === 8
                  ? 'lg:-translate-x-16 lg:-translate-y-20 z-20'
                  : 'z-10'
              }`}
            >
              <img
                src={nft.image}
                alt={nft.title}
                className="w-full h-32 sm:h-48 lg:h-56 object-cover rounded-xl"
              />
            </div>
          ))}
        </div>

        {/* Title Animation */}
        <div className="mt-10 w-full flex flex-col justify-center">
          <div className="flex justify-center">
            <motion.h2
              className="text-5xl sm:text-5xl md:text-6xl lg:text-huge font-bold font-montserrat text-transparent stroke-text text-center my-20"
              animate={{
                scale: [1, 1.05, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
              }}
            >
              NFT and Tokens
            </motion.h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reward;
