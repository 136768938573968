import React, { useState } from 'react';

const TaskSection = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ name: '', priceRange: '' });
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask({ ...newTask, [name]: value });
  };

  const handleAddTask = () => {
    if (newTask.name && newTask.priceRange) {
      setTasks([...tasks, newTask]);
      setNewTask({ name: '', priceRange: '' });
    }
  };

  const handleDeleteTask = (index) => {
    setTasks(tasks.filter((_, i) => i !== index));
  };

  const handleEditTask = (index) => {
    setEditingTaskIndex(index);
    setNewTask(tasks[index]);
  };

  const handleUpdateTask = () => {
    if (editingTaskIndex !== null && newTask.name && newTask.priceRange) {
      const updatedTasks = [...tasks];
      updatedTasks[editingTaskIndex] = newTask;
      setTasks(updatedTasks);
      setNewTask({ name: '', priceRange: '' });
      setEditingTaskIndex(null);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Admin Task Section</h2>

      {/* Add New Task Section */}
      <div className="mb-4">
        <input
          type="text"
          name="name"
          value={newTask.name}
          onChange={handleInputChange}
          placeholder="Task Name"
          className="p-2 border rounded mr-2"
        />
        <input
          type="text"
          name="priceRange"
          value={newTask.priceRange}
          onChange={handleInputChange}
          placeholder="Price Range (e.g. $10-20 USD)"
          className="p-2 border rounded mr-2"
        />
        {editingTaskIndex === null ? (
          <button onClick={handleAddTask} className="bg-blue-500 text-white px-4 py-2 rounded">
            Add Task
          </button>
        ) : (
          <button onClick={handleUpdateTask} className="bg-green-500 text-white px-4 py-2 rounded">
            Update Task
          </button>
        )}
      </div>

      {/* Task List Section */}
      <div className="overflow-y-auto max-h-[400px]">
        {tasks.length > 0 ? (
          tasks.map((task, index) => (
            <div key={index} className="p-4 mb-2 border rounded-lg flex justify-between items-center bg-gray-50">
              <div>
                <span className="font-semibold">{task.name}</span> -{' '}
                <span className="text-green-600 font-bold">{task.priceRange}</span>
              </div>
              <div>
                <button
                  onClick={() => handleEditTask(index)}
                  className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteTask(index)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No tasks available</p>
        )}
      </div>
    </div>
  );
};

export default TaskSection;
