import React from 'react';

const UserBlogsSection = () => {
  // Sample blog data
  const blogs = [
    {
      id: 1,
      title: "My Blood Donation Journey",
      description: "A reflection on my experiences and the impact of donating blood.",
      date: "October 10, 2024"
    },
    {
      id: 2,
      title: "How TrustBlu is Revolutionizing Blood Donation",
      description: "Exploring how blockchain technology is transforming the donation process.",
      date: "September 25, 2024"
    },
    {
      id: 3,
      title: "Why You Should Donate Blood Regularly",
      description: "Understanding the health benefits and community impact of regular donations.",
      date: "August 15, 2024"
    }
  ];

  return (
    <div className="p-5 bg-white">
      <h2 className="text-2xl font-bold text-black mb-4">Your Blogs</h2>
      <div className="grid grid-cols-3 gap-4">
        {blogs.map(blog => (
          <div key={blog.id} className="bg-gray-800 p-4 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-white">{blog.title}</h3>
            <p className="text-sm text-gray-400">{blog.date}</p>
            <p className="text-gray-300 mt-2">{blog.description}</p>
            <button className="text-blue-400 mt-3">Read More</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserBlogsSection;
