import React from 'react';

const TermsAndConditions = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-lg relative">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
        <div className="text-gray-600 space-y-4 max-h-96 overflow-y-auto">
          <p>
            Welcome to TrustBlu. By accessing or using our platform, you agree to the following terms and conditions. 
            Please read them carefully before proceeding.
          </p>
          <h3 className="font-semibold text-lg">1. Acceptance of Terms</h3>
          <p>
            By using our services, you acknowledge that you have read, understood, and agree to be bound by these terms.
            If you do not agree, you should not use our services.
          </p>
          <h3 className="font-semibold text-lg">2. Eligibility</h3>
          <p>
            To join the TrustBlu waitlist, you must be at least 16 years old and have the legal capacity to enter into 
            this agreement.
          </p>
          <h3 className="font-semibold text-lg">3. Use of Services</h3>
          <p>
            You agree to use TrustBlu only for lawful purposes and in a manner that does not infringe on the rights of others.
          </p>
          <h3 className="font-semibold text-lg">4. Privacy Policy</h3>
          <p>
            We value your privacy. By using our services, you agree to our privacy policy, which explains how we collect,
            use, and protect your personal information.
          </p>
          <h3 className="font-semibold text-lg">5. Changes to Terms</h3>
          <p>
            TrustBlu reserves the right to modify these terms at any time. We will notify you of any changes, and your
            continued use of our services will constitute acceptance of the new terms.
          </p>
          <h3 className="font-semibold text-lg">6. Contact Information</h3>
          <p>
            If you have any questions about these terms, please contact us at <a href="mailto:trustbluorg@gmail.com" className="text-customBlue underline">trustbluorg@gmail.com</a>.
          </p>
        </div>
        <div className="text-center mt-6">
          <button
            className="bg-customBlue text-white py-2 px-6 rounded-lg hover:bg-customRed transition-colors"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
