import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import D1 from '../../assets/D1.png';
import D2 from '../../assets/D2.png';

const SneakPeak = () => {
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const sectionRef = useRef(null);
  const timerRef = useRef(null); // To keep track of the timer

  // Array of images to display
  const images = [D1, D2, D1, D2];

  useEffect(() => {
    const section = sectionRef.current;
    let observer;

    if (section) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // Start the timer if it's not already running
            if (!timerRef.current) {
              let index = 0;
              setHighlightedIndex(index);

              timerRef.current = setInterval(() => {
                index = (index + 1) % images.length;
                setHighlightedIndex(index);
              }, 3000);
            }
          } else {
            // Clear the timer if the section is out of view
            if (timerRef.current) {
              clearInterval(timerRef.current);
              timerRef.current = null;
            }
          }
        },
        { threshold: 0.3 } // Adjust as needed
      );

      observer.observe(section);
    }

    return () => {
      // Cleanup observer and timer on component unmount
      if (observer && section) {
        observer.unobserve(section);
      }
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [images.length]);

  return (
    <section
      ref={sectionRef}
      className="sneak-peak-section py-10 bg-white flex items-center justify-center p-4 mx-5 md:mx-20 mt-10 mb-20 bg-white rounded-3xl shadow-md border border-gray-200"
    >
      <div className="container mx-auto flex flex-col items-center gap-6">
        <div className="flex flex-col md:flex-row items-center justify-around w-full mb-8 gap-8">
          {[
            {
              icon: '📅', // Calendar icon for booking
              title: ['Book', 'Donation'],
              description:
                'Easily schedule your next blood donation. TrustBlu makes booking simple and rewarding.',
            },
            {
              icon: '🗓️', // Schedule icon for viewing appointments
              title: ['View', 'Appointments'],
              description:
                "Keep track of all your appointments with this user-friendly interface.",
            },
            {
              icon: '✅', // Checkmark icon for confirming donation
              title: ['Confirm', 'Donation'],
              description:
                'Cherish your contribution and the impact it would make in the lives of others.',
            },
            {
              icon: '🏆', // Trophy icon for viewing rewards
              title: ['View', 'Rewards'],
              description:
                'View and track your rewards for each donation, recognizing your individual role in the community.',
            },
          ].map((item, index) => (
            <div
              key={index}
              className={`flex flex-col items-center md:items-start text-center md:text-left transition-opacity duration-1000 ${highlightedIndex === index ? 'opacity-100' : 'opacity-50 grayscale'
                }`}
              style={{
                minHeight: '250px', // Ensure consistent height
                transition: 'opacity 1s ease, filter 1s ease',
              }}
            >
              <div className="flex flex-col items-center md:items-start justify-center mb-4">
                <span className="bg-gray-100 rounded-full p-3 text-lg md:text-2xl mb-2">{item.icon}</span>
                <h3 className="text-xl md:text-2xl font-semibold text-gray-800">
                  {item.title[0]} <span className="block">{item.title[1]}</span>
                </h3>
              </div>
              <p className="text-gray-500 text-sm md:text-base leading-relaxed">
                {item.description}
              </p>
            </div>
          ))}
        </div>

        {/* Sneak Peek of Dashboard Section */}
        <div
          className="sneak-peak-dashboard w-full h-[200px] sm:h-[500px] overflow-hidden relative"
        >
          <AnimatePresence mode="wait">
            <motion.img
              key={highlightedIndex}
              src={images[highlightedIndex]}
              alt="Sneak Peek of Dashboard"
              className="rounded-md shadow-lg w-full h-full object-cover"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 1, ease: 'easeInOut' }}
              style={{
                height: '100%', // Ensure the image takes the full height of the container
                width: '100%',  // Ensure the image takes the full width of the container
                position: 'absolute',
                objectFit:'contain',
                margin: '0 auto',
              }}
            />
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default SneakPeak;
