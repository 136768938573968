import React from 'react';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <footer id="Contact Us" className="bg-gradient-to-l from-blue-500 to-red-500 text-white py-8">
      <div className="container mx-auto px-4 lg:px-16 border-t border-white">
        <div className="flex flex-wrap justify-between items-start mb-8 mt-10 space-y-6 lg:space-y-0 lg:flex-nowrap lg:space-x-16">

          {/* Logo and description */}
          <div className="w-full lg:w-1/4 text-center lg:text-left mb-4 lg:mb-0">
            <a className="flex items-center justify-center lg:justify-start no-underline" href="#">
              <span className="text-customRed font-bold text-3xl lg:text-4xl">Trust</span>
              <span className="text-customBlue font-bold text-3xl lg:text-4xl ml-1">Blu</span>
            </a>
          </div>

          {/* Footer Links */}
          <div className="w-full lg:w-1/4 text-center lg:text-left mb-4 lg:mb-0">
            <h4 className="text-lg lg:text-xl font-semibold mb-4 underline">Quick Links</h4>
            <ul className="space-y-2">
              <li>
                <Link
                  to="Home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="hover:text-customBlue transition-colors no-underline cursor-pointer"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="About"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="hover:text-customBlue transition-colors no-underline cursor-pointer"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="Why TrustBlu"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="hover:text-customBlue transition-colors no-underline cursor-pointer"
                >
                  Why TrustBlu
                </Link>
              </li>
              <li>
                <Link
                  to="Reward"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="hover:text-customBlue transition-colors no-underline cursor-pointer"
                >
                  Rewards
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="w-full lg:w-1/4 text-center lg:text-left">
            <h4 className="text-lg lg:text-xl font-semibold mb-4 underline">Contact Us</h4>
            <p className="text-white">Email: trustbluorg@gmail.com</p>
            <p className="text-white">Phone: +1 (289) 623-0427</p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="w-full border-t border-white pt-4 text-center">
          <p className="text-white text-sm lg:text-base">© 2024 TrustBlu. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
