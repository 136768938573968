import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './Components/Landing/LandingPage';
import UserDashboard from './Components/Dashboard/Dashboard'; // Import your Dashboard component
import AdminDashboard from './Components/AdminDashboard/Dashboard';
import InstitutionDashboard from './Components/InstitutionDashboard';
import AuthPage from './Components/AuthPage';
import RoleSelectionPage from './Components/RoleSelection';
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" />
          <Route path="/auth" element={<RoleSelectionPage />} />
          <Route path="/auth/:role" element={<AuthPage />} />
          <Route path="/user-dashboard" element={<UserDashboard />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/institution-dashboard" element={<InstitutionDashboard />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
