import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/Logo1.jpg';
import { Link } from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const offsetValue = window.innerWidth > 1024 ? -100 : -70;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleDashboardRedirect = () => {
    setIsOpen(false); // Ensure menu is closed on redirect
    navigate("/dashboard");
  };

  // Handle scroll event to add blur for laptop screen sizes and above
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 768 && !isOpen) {
        setIsScrolled(window.scrollY > 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return (
    <>
      <header
        className={`fixed top-0 w-full py-3 z-50 ${
          isScrolled && !isOpen ? "backdrop-blur-md border-b border-gray-200 transition-all duration-300" : "bg-white border-b border-gray-200"
        } ${isOpen ? "bg-white border-b border-gray-200 !transition-none" : ""}`}
      >
        <div className="container mx-auto px-4 lg:px-0 flex items-center justify-between">
          {/* Logo Section */}
          <a className="flex items-center no-underline" href="#">
            <span className="text-customRed font-bold text-2xl">Trust</span>
            <span className="text-customBlue font-bold text-2xl">Blu</span>
            <img src={Logo} alt="Logo" className="h-10 -ml-1" />
          </a>

          {/* Hamburger for Mobile Screens */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>

          {/* Navbar Links - Hidden on mobile */}
          <ul className="hidden md:flex justify-center space-x-8 ml-10">
            {['Home', 'About', 'Why TrustBlu', 'Reward'].map((link, index) => (
              <li key={index} className="nav-item">
                <Link
                  to={link}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="text-customBlue hover:text-customRed transition-colors no-underline cursor-pointer"
                >
                  {link}
                </Link>
              </li>
            ))}
          </ul>

          {/* Right aligned buttons - Hidden on mobile */}
          <div className="hidden md:flex space-x-4 ml-auto">
            <Link
              to="Contact Us"
              spy={true}
              smooth={true}
              offset={offsetValue}
              duration={500}
              className="bg-customBlue text-white px-4 py-2 rounded hover:bg-customRed transition-colors"
            >
              Contact Us
            </Link>
            {/* <button
      onClick={() => {
        handleDashboardRedirect();
        toggleMenu();
      }}
      className="block bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors w-full"
    >
      Go to Dashboard
    </button> */}
          </div>
        </div>

        {/* Mobile Dropdown from the Right */}
        <div
          className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg z-40 transform transition-transform duration-300 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="pt-16 px-6">
            {['Home', 'About', 'Why TrustBlu', 'Reward'].map((link, index) => (
              <Link
                key={index}
                to={link}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggleMenu}
                className="block text-customBlue hover:text-customRed transition-colors no-underline cursor-pointer mb-4"
              >
                {link}
              </Link>
            ))}
            <Link
              to="Contact Us"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={toggleMenu}
              className="block bg-customBlue text-white px-4 py-2 rounded hover:bg-customRed transition-colors mb-4"
            >
              Contact Us
            </Link>
          </div>
        </div>

        {/* Gradient border at the bottom of header */}
        <div className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-red-500 animate-gradient"></div>
      </header>

      {/* Wrapper for blurring and darkening the background content */}
      <div
        className={`transition-all duration-300 ${
          isOpen ? "blur-sm bg-black bg-opacity-50 fixed inset-0 z-30" : ""
        }`}
      >
      </div>

      <style jsx>{`
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 100% 50%;
          }
        }
        .animate-gradient {
          background-size: 200% 200%;
          animation: gradient 5s linear infinite;
        }
      `}</style>
    </>
  );
};

export default Navbar;
