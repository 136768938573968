import React from 'react';

import TaskSection from './TaskSection';


import Navbar from './Navbar';
import NftMintingList from './NftMintingList';

const Dashboard = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Main Dashboard Content */}
      <div className="flex-grow w-full px-4 lg:px-8 py-20 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Left Side */}
          <div className="lg:col-span-2 space-y-4">
            <NftMintingList />
            <TaskSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
