import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

Modal.setAppElement('#root'); // Set your app element for accessibility

const AppointmentsSection = () => {
  const appointmentDetails = {
    title: 'Next Appointment',
    date: 'October 10, 2024',
    time: '10:30 AM',
    location: 'Downtown Health Center',
  };

  const questions = [
    "Are you 17 years old or more?",
    "Have you had a tattoo or piercing in the last three months?",
    "Have you travelled outside of Canada, the continental USA, Antarctica or Europe in the last three weeks?",
    "Have you been to the dentist for an extraction, surgery or root canal in the last three days?",
    "Are you pregnant, have been pregnant or had a baby in the last six months?",
    "Are you taking prescription medication(s)?",
    "Do you weigh more than 50 kg (110 lb.)?"
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEligibilityModalOpen, setIsEligibilityModalOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [centerName, setCenterName] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [isEligible, setIsEligible] = useState(false); // Initial state is false
  const [isFinished, setIsFinished] = useState(false);
  const [isEligibilityChecked, setIsEligibilityChecked] = useState(false); // To trigger the animation

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openEligibilityModal = () => {
    setIsEligibilityModalOpen(true);
    setCurrentQuestion(0); // Reset to first question
    setIsFinished(false);   // Ensure eligibility check hasn't finished yet
    // Do not reset isEligible here, it will be updated based on answers
  };

  const closeEligibilityModal = () => {
    setIsEligibilityModalOpen(false);
  };

  const handleCheckEligibility = (answer) => {
    if (currentQuestion === 0) {
      if (answer === 'yes') {
        setCurrentQuestion(1);
      } else {
        setIsEligible(false);
        setIsFinished(true);
        setIsEligibilityChecked(false); // End check if not eligible
      }
    } else if (currentQuestion === 1) {
      if (answer === 'no') {
        setCurrentQuestion(2);
      } else {
        setIsEligible(false);
        setIsEligibilityChecked(false);
        setIsFinished(true); // End check if not eligible
      }
    } else if (currentQuestion === 2) {
      if (answer === 'no') {
        setCurrentQuestion(3);
      } else {
        setIsEligible(false);
        setIsEligibilityChecked(false);
        setIsFinished(true); // End check if not eligible
      }
    } else if (currentQuestion === 3) {
      if (answer === 'no') {
        setCurrentQuestion(4); 
      } else {
        setIsEligible(false);
        setIsEligibilityChecked(false);
        setIsFinished(true); // End check if not eligible
      }
    } else if (currentQuestion === 4) {
      if (answer === 'no') {
        setCurrentQuestion(5); 
      } else {
        setIsEligible(false);
        setIsEligibilityChecked(false);
        setIsFinished(true); // End check if not eligible
      }
    } else if (currentQuestion === 5) {
      if (answer === 'no') {
        setCurrentQuestion(6); 
      } else {
        setIsEligible(false);
        setIsEligibilityChecked(false);
        setIsFinished(true); // End check if not eligible
      }
    } else if (currentQuestion === 6) {
      if (answer === 'yes') {
        setIsFinished(true);
        setIsEligible(true); // Mark as eligible
        setIsEligibilityChecked(true); // Trigger animation
        // After some delay, the animation will turn into a check mark
      } else {
        setIsEligible(false);
        setIsFinished(true);
        setIsEligibilityChecked(false)// End check if not eligible
      }
    }
  };

  const handleBookAppointment = () => {
    console.log(`Date: ${selectedDate}, Center: ${centerName}, Time: ${selectedTime}`);
    closeModal();
  };

  const timeSlots = [
    '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM',
    '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM',
    '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM',
  ];

  return (
    <motion.div
      className="p-4 text-white rounded-lg shadow-2xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >
      <h2 className="text-lg font-bold mb-4">Appointments</h2>
      <div className="flex space-x-4">
        <button
          onClick={openEligibilityModal}
          className={`px-4 py-2 rounded-lg mb-6 w-[40%] ${isEligible ? 'bg-green-500' : 'bg-red-500'}`}
        >
          {isEligibilityChecked ? (
            <motion.div
              animate={{ rotate: [0, 360] }} // Rotate animation
              transition={{ duration: 1, repeat: 1, ease: 'easeInOut' }}
            >
              ✅
            </motion.div>
          ) : (
            'Check Eligibility'
          )}
        </button>
        <button
          onClick={openModal}
          className={`px-4 py-2 rounded-lg mb-6 ${isEligible ? 'bg-blue-500' : 'bg-gray-500'}`}
          disabled={!isEligible} // Disable button if not eligible
        >
          Book Appointment
        </button>
      </div>

      <div className="p-4 bg-blue-500 text-white rounded-lg shadow-md mt-6">
        <h3 className="text-lg font-bold mb-2">{appointmentDetails.title}</h3>
        <p className="mb-1">
          <span className="font-semibold">Date: </span>{appointmentDetails.date}
        </p>
        <p className="mb-1">
          <span className="font-semibold">Time: </span>{appointmentDetails.time}
        </p>
        <p>
          <span className="font-semibold">Location: </span>{appointmentDetails.location}
        </p>
      </div>

      {/* Modal for Booking Appointment */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Book Appointment"
        className="modal bg-white p-6 rounded-lg shadow-lg"
        overlayClassName="overlay bg-gray-900 bg-opacity-75 fixed inset-0 flex justify-center items-center z-20"
      >
        <h2 className="text-xl font-bold mb-4">Book Appointment</h2>
        <div className="flex">
          <div className="flex-1 pr-4">
            <div className="mb-4">
              <label className="block font-semibold mb-2">Center Name:</label>
              <input
                type="text"
                value={centerName}
                onChange={(e) => setCenterName(e.target.value)}
                className="p-2 border rounded-lg w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block font-semibold mb-2">Select Time:</label>
              <div className="grid grid-cols-3 gap-2">
                {timeSlots.map((slot) => (
                  <button
                    key={slot}
                    className={`p-2 w-24 h-12 rounded-lg flex items-center justify-center text-center mx-3 ${
                      selectedTime === slot ? 'bg-blue-600 text-white' : 'bg-gray-200'
                    }`}
                    onClick={() => setSelectedTime(slot)}
                  >
                    {slot}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="flex-1 pl-4">
            <div className="mb-4">
              <label className="block font-semibold mb-2">Select Date:</label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                className="p-2 border rounded-lg w-full"
                inline
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-4 mt-6">
          <button onClick={closeModal} className="bg-gray-500 text-white px-4 py-2 rounded-lg">
            Cancel
          </button>
          <button
            onClick={handleBookAppointment}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg"
            disabled={!selectedTime || !centerName}
          >
            Book Appointment
          </button>
        </div>
      </Modal>

      {/* Eligibility Check Modal */}
      <Modal
        isOpen={isEligibilityModalOpen}
        onRequestClose={closeEligibilityModal}
        contentLabel="Check Eligibility"
        className="modal bg-white p-6 rounded-lg shadow-lg"
        overlayClassName="overlay bg-gray-900 bg-opacity-75 fixed inset-0 flex justify-center items-center z-20"
        style={{
          content: {
            width: '500px', // Fixed width
            height: '400px', // Fixed height
            overflow: 'hidden', // Prevent resizing the modal based on content
          },
        }}
      >
        <h2 className="text-xl font-bold mb-4">Eligibility Check</h2>
        <div className="overflow-y-auto h-64"> {/* Scrollable area */}
          {!isFinished && (
            <div>
              <p className="mb-4">{questions[currentQuestion]}</p>
              <div className="flex space-x-4">
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg"
                  onClick={() => handleCheckEligibility('yes')}
                >
                  Yes
                </button>
                <button
                  className="bg-red-600 text-white px-4 py-2 rounded-lg"
                  onClick={() => handleCheckEligibility('no')}
                >
                  No
                </button>
              </div>
            </div>
          )}

          {isFinished && isEligible && <p className="text-green-600">You are eligible to donate!</p>}
          {isFinished && !isEligible && <p className="text-red-600">You are not eligible to donate at this time.</p>}
        </div>

        <div className="flex justify-end space-x-4 mt-6">
          <button onClick={closeEligibilityModal} className="bg-gray-500 text-white px-4 py-2 rounded-lg">
            Close
          </button>
        </div>
      </Modal>
    </motion.div>
  );
};

export default AppointmentsSection;
