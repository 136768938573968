import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi"; // Import the logout icon

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    // Add your logout logic here
    console.log("Logout clicked");
    // Optionally navigate to the login page or perform logout operations
  };

  // Handle scroll event to add blur for laptop screen sizes and above
  useEffect(() => {
    const handleScroll = () => {
      if (!isOpen) {
        setIsScrolled(window.scrollY > 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return (
    <>
      <header
        className={`fixed top-0 w-full py-3 z-50 ${
          isScrolled && !isOpen
            ? "backdrop-blur-md transition-all duration-300"
            : "bg-gradient-to-r from-red-500 to-blue-500"
        } ${isOpen ? "bg-white !transition-none" : ""}`}
      >
        <div className="container mx-auto px-4 lg:px-0 flex items-center justify-between">
          {/* Centered TrustBlu Text */}
          <a className="flex items-center no-underline" href="#">
            <span
              className={`font-bold text-3xl transition-colors duration-300 ${
                isScrolled ? "text-customRed" : "text-black"
              }`}
            >
              Trust
            </span>
            <span
              className={`font-bold text-3xl transition-colors duration-300 ${
                isScrolled ? "text-customBlue" : "text-black"
              }`}
            >
              Blu
            </span>
          </a>

          {/* Right-aligned Logout Icon */}
          <div className="flex items-center space-x-4">
            {/* Hamburger for Mobile Screens */}
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                  />
                </svg>
              </button>
            </div>

            {/* Logout Button for Desktop */}
            <button
              onClick={handleLogout}
              className="hidden md:block text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900"
              title="Logout"
            >
              <FiLogOut className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Mobile Dropdown */}
        <div
          className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg z-40 transform transition-transform duration-300 ease-in-out ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {/* Only Logout in the mobile dropdown */}
          <nav className="mt-8 space-y-4">
            <button
              onClick={handleLogout}
              className="block px-4 py-2 text-gray-800 flex items-center"
            >
              <FiLogOut className="h-6 w-6 mr-2" />
              Logout
            </button>
          </nav>
        </div>
      </header>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 z-30"
          onClick={toggleMenu}
        ></div>
      )}
    </>
  );
};

export default Navbar;
