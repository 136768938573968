import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleDashboardRedirect = () => {
    setIsOpen(false); // Ensure menu is closed on redirect
    navigate("/dashboard");
  };

  // Handle scroll event to add blur for laptop screen sizes and above
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 768 && !isOpen) {
        setIsScrolled(window.scrollY > 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return (
    <>
      <header
        className={`fixed top-0 w-full py-3 z-50 ${
          isScrolled && !isOpen ? "backdrop-blur-md transition-all duration-300" : "bg-white "
        } ${isOpen ? "bg-white !transition-none" : ""}`}
      >
        <div className="container mx-auto px-4 lg:px-0 flex items-center justify-center">
          {/* Centered TrustBlu Text */}
          <a className="flex items-center no-underline" href="#">
            <span className="text-customRed font-bold text-2xl">Trust</span>
            <span className="text-customBlue font-bold text-2xl ml-1">Blu</span>
          </a>

          {/* Hamburger for Mobile Screens */}
          <div className="md:hidden absolute right-4">
            <button
              onClick={toggleMenu}
              className="text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Dropdown from the Right */}
        <div
          className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg z-40 transform transition-transform duration-300 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </header>

      {/* Wrapper for blurring and darkening the background content */}
      <div
        className={`transition-all duration-300 ${
          isOpen ? "blur-sm bg-black bg-opacity-50 fixed inset-0 z-30" : ""
        }`}
      >
      </div>
    </>
  );
};

export default Navbar;
